<script>
import { onBeforeMount, ref } from 'vue'
import { formatDate } from '/~/utils/format/date'
import Storage from '/~/utils/localStorage'
import BaseButton from '/~/components/base/button/base-button.vue'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import BaseLoader from '/~/components/base/loader/base-loader.vue'
import LayoutRounded from '/~/layouts/rounded/layout-rounded.vue'
import ConfirmationFormData from '/~/templates/australiapost/components/lead-submission/confirmation-form-data.vue'

export default {
  name: 'lead-submission-confirmation',
  components: {
    ConfirmationFormData,
    LayoutRounded,
    BaseLoader,
    BaseButton,
    BaseIcon,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  setup(props, { emit }) {
    const form = ref({})
    const loading = ref(true)
    const failed = ref(false)

    onBeforeMount(() => {
      // TODO: replace with endpoint
      form.value = Storage.get(`lead-submission-${props.id}`)
      loading.value = false

      if (!form.value) {
        failed.value = true
        // TODO: remove when we add endpoint
        emit('router:replace', { name: 'lead-submission' })
      }
    })

    return {
      loading,
      failed,
      form,
      formatDate,
    }
  },
}
</script>

<template>
  <layout-rounded>
    <div class="flex min-h-full w-full">
      <div
        class="flex min-h-full w-full flex-col rounded-t-3xl bg-eonx-neutral-50 sm:mx-6 sm:pt-[30px]"
      >
        <div
          class="relative mx-auto flex min-h-full w-full max-w-lg flex-col bg-white sm:rounded-t-3xl sm:pt-[35px]"
        >
          <div class="min-h-full px-5 pt-10 sm:px-10 sm:pt-0">
            <base-loader v-if="loading" fullwidth />

            <div
              v-else-if="failed"
              class="py-[30px] text-center text-2xl font-bold"
            >
              Lead submission information not found

              <div class="mt-[30px]">
                <base-button full-width @click="$router.push({ name: 'home' })">
                  Back to Dashboard
                </base-button>
              </div>
            </div>

            <template v-else>
              <div class="flex items-center justify-center px-5 pb-5 md:px-10">
                <div class="flex flex-col items-center">
                  <div
                    class="flex h-24 w-24 items-center justify-center rounded-full bg-green-400 text-white"
                  >
                    <base-icon svg="plain/check" :size="50" />
                  </div>
                  <span class="mt-6 text-2xl font-bold">
                    Thank you for your entry!
                  </span>
                </div>
              </div>

              <div class="mb-6 text-center">
                <div class="font-bold leading-relaxed text-eonx-neutral-600">
                  Lead No. #{{ id }}
                </div>
                <div
                  v-if="form.createdAt"
                  class="mt-[5px] font-bold leading-relaxed text-eonx-neutral-600"
                >
                  Submitted
                  {{ formatDate('daymonthyeartime', form.createdAt) }}
                </div>
              </div>

              <confirmation-form-data :form="form" />

              <div class="sticky bottom-0 -mx-5 bg-white px-5 py-6 sm:-mx-10">
                <div class="flex w-full space-x-6">
                  <base-button
                    class="mx-auto w-1/3"
                    @click="$router.push({ name: 'home' })"
                  >
                    Got it
                  </base-button>
                </div>
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
  </layout-rounded>
</template>
